<template>
  <v-flex align-self-center class="email-login">
    <h1 class="text-center mb-8" v-t="'login.title'"></h1>

    <v-text-field outlined hide-details class="mb-3" label="E-mail" v-model="email"></v-text-field>

    <v-text-field
      outlined
      hide-details
      class="mb-3"
      label="Password"
      :type="PWShow ? 'text' : 'password'"
      :append-icon="PWShow ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="PWShow = !PWShow"
      v-model="passwd"
      @keypress.enter="login"></v-text-field>

    <x-large-button class="mt-6" color="secondary" :loading="loading" @click="login">
      <h2 v-t="'login.button.login'"></h2>
    </x-large-button>

    <!-- <div class="mt-2 mb-8 text-right">
      <small-link-text v-t="'login.findPassword'" routeName="forgotPassword" />
    </div> -->

    <v-divider />

    <x-large-button class="my-8" color="secondary" :outlined="true" @click="$router.push({ name: 'signUp' })">
      <h2 v-t="'login.button.signUp'"></h2>
    </x-large-button>
  </v-flex>
</template>

<script>
import { hasValue } from "@/utils/util";
import XLargeButton from "@/components/common/buttons/XLargeButton.vue";
// import SmallLinkText from "@/components/common/texts/SmallLinkText.vue";

export default {
  data() {
    return {
      email: "",
      passwd: "",
      PWShow: false,
      loading: false,
    };
  },
  components: {
    XLargeButton,
    // SmallLinkText
  },
  methods: {
    login() {
      if (!hasValue(this.email)) return this.$toast.info("Please enter email");
      if (!hasValue(this.passwd)) return this.$toast.info("Please enter password");

      this.loading = true;

      this.$store
        .dispatch("user/login", { email: this.email, passwd: this.passwd })
        .then(() => {
          this.$store
            .dispatch("user/getCompanyList")
            .then(() => {
              this.$router.push("/");
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
