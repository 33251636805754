<template>
  <v-btn
    :block="block === undefined ? true : block"
    x-large
    elevation="0"
    class="pa-8"
    :color="color"
    :outlined="outlined"
    :loading="loading"
    @click="callback($event)">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: ["color", "outlined", "block", "loading"],
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style></style>
